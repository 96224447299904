@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

html, body {font-family: 'Montserrat', sans-serif;  font-size: 13px; /*overflow-x: hidden;*/ color:#666666;width:100%; height: 100%; background: #fff}
* {box-sizing:border-box;outline: none !important; font-family: 'Montserrat', sans-serif;}

header {
    background: #fff;
    box-shadow:
    0 0px 0.7px rgba(0, 0, 0, 0.017),
    0 -0.1px 1.9px rgba(0, 0, 0, 0.025),
    0 0.1px 4.5px rgba(0, 0, 0, 0.033),
    0 2px 15px rgba(0, 0, 0, 0.05)
  ;

}

.topnav li a { margin-top: 5px; display: inline-block; margin-left:30px; color: #005670; font-weight: 600; font-size: 15px; text-transform: uppercase; line-height: 57px; }
.topnav li a:hover {color: #009ca6}
.topnav li a:after {display:block; content: ''; border-bottom: solid 3px #009ca6; transform: scaleX(0); transition: transform 150ms ease-in-out; }
.topnav li a:hover:after {transform: scaleX(1);}
.topnav li a.active {color: #009ca6}
.topnav li a.active:after {transform: scaleX(1);}

ul.menuprincipal li div {
    left: 15px;
    width: 180px;
    background-color: #fff;
    opacity: 0;
    top: 120px;
    margin-top: 0;
    position: absolute;
    transition: background-color .5s ease;
    text-align: left;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    visibility: hidden;
    box-shadow:
    0 0.1px 0.1px rgba(0, 0, 0, 0.022),
    0 0.2px 0.3px rgba(0, 0, 0, 0.031),
    0 0.4px 0.7px rgba(0, 0, 0, 0.039),
    0 0.7px 1.5px rgba(0, 0, 0, 0.048),
    0 2px 4px rgba(0, 0, 0, 0.07)
  ;
}

ul.menuprincipal li div a {
    color: #005670;
    font-weight: 400;
}

.logo-img {
    max-width: 350px;
    box-shadow: none!important;
}

.text-blue, .slider-data {
    color: #005670;
}

.bars-menu span { display: block; position: absolute; top: 15px; left: 0px; right: 0px; height: 3px; background:#005670 }
.bars-menu span::before, .bars-menu span::after {position: absolute; display: block; left: 0; width: 100%; height: 3px; background:#005670; content: ""; -webkit-transition-duration: 0.3s, 0.3s; transition-duration: 0.3s, 0.3s; -webkit-transition-delay: 0.3s, 0s; transition-delay: 0.3s, 0s;}
.bars-menu span::before { top: -9px; -webkit-transition-property: top, -webkit-transform; transition-property: top, transform; }

.btn-primary {
    background: #005670!important;
    border-color: #005670!important;
    color: #fff!important;
}

.pagination>li:first-child.active>a, .pagination>li:first-child.active>span {
    background-color: #005670;
    border-color: transparent;
    color: #fff;
}

.pagination>li>a, .pagination>li>span {
    color: #005670;
    margin-left: 3px;
    padding: 6px 12px;
    border: 1px solid #005670;
}

a.btn-azul-grande {
    background-color: #005670;
}

a.press-release-link {
    color: #009ca6;
}

a.press-release-link:hover {
    color: #005670;
}

.slider-aep .owl-theme .owl-controls .owl-nav [class*=owl-] {
    background: #005670;
}

.circulo-verde-small, .circulo-verde {
    background-color: #009ca6;
}

.circulo-verde::before {
    border: 2px solid #005670;
}

.btn.newsletter {
    background-color: #009ca6;
}

.link-callout {
    background-color: #009ca6;
}

a.newsletter-link {
    color: #005670;
}

.titulo-paginas {
    border-left: 10px solid #009ca6;
}

a.link-paginas {
    color: #009ca6;
}

.accordion-header { padding: 20px; font-size: 16px; color: #009ca6; cursor: pointer; font-weight: 400}
.accordion-content {margin: 0; border-bottom: 1px solid #ddd;}
.accordion-content div {padding: 20px;}
.fa-chevron-down {margin-left: 5px; font-size: 14px;}

/* Versao que rodava o font awesome angle down no acordeo - FICA AQUI COMO REFERENCIA */
.accordion.open .rotate-if-collapsed {-webkit-transform: rotate(180deg); -moz-transform: rotate(180deg); -ms-transform: rotate(180deg); -o-transform: rotate(180deg); transform: rotate(180deg); }
.accordion.open .accordion-header {background-color: #009ca6; color: #fff; border-bottom: 0px;}

/* versao em uso de mais/menos no acordeao */
.icon {box-sizing: border-box; transition: transform 0.4s; width: 24px; height: 24px; transform: rotate(180deg); position: relative; }
.accordion.open .icon {transform: rotate(0deg); }
.icon::before {content: ''; display: block; width: 20px; height: 0px; border-bottom: solid 2px #009ca6; position: absolute; bottom: 11px; transform: rotate(90deg); transition: width 0.4s; }
.accordion.open .icon::before {content: ''; display: block; width: 0px; height: 0px; border-bottom: solid 2px #fff; position: absolute; bottom: 11px; transform: rotate(90deg); }
.icon::after {content: ''; display: block; width: 20px; height: 0px; border-bottom: solid 2px #009ca6; position: absolute; bottom: 11px; }
.accordion.open  .icon::after {content: ''; display: block; width: 20px; height: 0px; border-bottom: solid 2px #fff; position: absolute; bottom: 11px; }

#formcontactos button, #formbow button, #formPropostaSocioEfectivo button, #formCertificadoOrigemNovo button, #formCertificadoOrigem button, #formepago button, #formegratuito button {
    background: #005670;
}

.text-blue-novo {
    color: #005670
}

.text-blue-claro-novo {
    color: #009ca6
}

.mb-30 {
	margin-bottom: 30px
}

.mb-20 {
	margin-bottom: 20px
}

.ta-100 {
    height: 100px
}

.b-none {
    border: none;
}

.font-9 {
    font-size: 9px;
}

.font-11 {
    font-size: 11px;
}

.btn-203 {
    max-width: 203px!important;
    margin-bottom: 10px
}

.no-wrap {
    white-space: nowrap;
}

#form-submete-qualifica .g-recaptcha {
    display: block;
}

.border-solid {
    border: solid;
}

.margin-6 {
    margin: 6px;
}

.dis-none {
    display: none;
}

.dis-block {
    display: block;
}

.vis-hidden {
    visibility: hidden;
}

.ov-hidden {
    overflow: hidden;
}

.min-height-500 {
    min-height: 500px;
}

.height-80 {
    height: 80px;
}

.height-100 {
    height: 100px;
}

.transform-initial {
    text-transform: initial;
}

.checkbox-concordo {
    line-height:20px;
    height:initial!important;
}

.bg-white {
    background-color: #fff;
}

.pad7-font13 {
    padding-top: 7px;
    font-size: 13px
}

.mt-40 {
    margin-top: 40px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-50 {
    margin-top: 50px;
}

.bkg-verde {
    background-color: #c9ff23;
}

.bkg-cinza {
    background-color: #e8e8e8;
}

.my-30 {
    margin: 0 30px
}

.mb-200 {
    margin-bottom:200px;
}

.mt-15 {
    margin-bottom:15px;
}

.maxheight-100 {
    max-height: 100%!important;
}

.mt-5porcento {
    margin-top: -5%;
}

.font-1em {
    font-size: 1em;
}

.pointer-none {
    pointer-events: none;
}

.mleft-0 {
    margin-left: 0;
}

.cursor-pointer {
    cursor: pointer;
}


@media (max-width: 767px){
    .logo-img {
        width: 200px;
    }

    .compensa-header-home {
        margin-top: 73px;
    }

    .bars-menu {
        margin-top: 8px;
    }
}