/* ADMINBAR */
.adminbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background: #191d38;
  color: #fff;
  padding: 0px 15px;
  position: fixed;
  top: -40px;
  left: 0;
  right: 0;
  z-index: 999999999999;
}
.adminbar a {
  color: #fff;
  text-decoration: none;
}
.adminbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.adminbar.adminbar.open {
  top: 0;
}
.adminbar .admin-user-actions li {
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}
.adminbar .admin-user-actions li:first-child {
  margin-bottom: 3px;
}
.adminbar .admin-user-actions li:hover a {
  opacity: 0.75;
}
.adminbar .admin-user-actions li i {
  line-height: 24px;
}
.adminbar .admin-page-actions {
  position: relative;
  padding-right: 15px;
  margin-bottom: auto;
}
.adminbar .admin-page-actions:after {
  content: "";
  position: absolute;
  top: 4px;
  right: 0;
  height: 15px;
  width: 1px;
  background: #757575;
}
.adminbar .admin-page-actions li {
  float: left;
  margin-left: 10px;
  vertical-align: middle;
}
.adminbar .admin-page-actions li:hover a {
  opacity: 0.75;
}
.adminbar .admin-page-actions li i {
  font-size: 14px;
  line-height: 24px;
  float: left;
  margin-right: 5px;
}
.adminbar .logo .brand-text {
  margin-left: 10px;
}
.adminbar .studio-logo-fixed {
  position: fixed;
  cursor: pointer;
  top: 50px;
  right: 10px;
  border: 3px solid transparent;
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #191d38;
  border-radius: 50px;
  box-shadow: 10px 10px 5px -9px rgba(0, 0, 0, 0.45);
}
.adminbar .studio-logo-fixed:hover {
  border: 3px solid #fff;
}

/* ======= VARIABLES ======= */
:root {
  --primary: hsla(0, 0%, 10%, 1);
  --secondary: hsla(320, 2%, 39%, 1);
  --light: #d4dad9;
  --dark: #202020;
  --info: #80838a;
  --success: #3c9b5f;
  --warning: #e3c83f;
  --danger: #eb242c;
  --body-color: #fff;
  --text-color: #222222;
  --header-height:72px;
  --section-gap: 80px;
  --margin: 30px;
  --border-radius: 0;
  --primary-hover: hsla(0, 0%, 20%, 1);
  --primary-text: #ffffff;
  --primary-text-hover: #ffffff;
  --primary-border: 0;
  --primary-border-color: hsla(0, 0%, 10%, 1);
  --primary-border-hover: hsla(0, 0%, 20%, 1);
  --secondary-hover: hsla(320, 2%, 30%, 1);
  --secondary-text: #ffffff;
  --secondary-text-hover: #ffffff;
  --secondary-border: 0;
  --secondary-border-color: hsla(320, 2%, 39%, 1);
  --secondary-border-hover: hsla(320, 2%, 30%, 1);
  --btn-style-1: hsla(320, 2%, 39%, 1);
  --btn-style-1-hover: hsla(320, 2%, 30%, 1);
  --btn-style-1-text: #ffffff;
  --btn-style-1-text-hover: #ffffff;
  --btn-style-1-border: 0;
  --btn-style-1-border-color: hsla(320, 2%, 39%, 1);
  --btn-style-1-border-hover: hsla(320, 2%, 30%, 1);
  --btn-style-2: hsla(320, 2%, 39%, 1);
  --btn-style-2-hover: hsla(320, 2%, 30%, 1);
  --btn-style-2-text: #ffffff;
  --btn-style-2-text-hover: #ffffff;
  --btn-style-2-border: 0;
  --btn-style-2-border-color: hsla(320, 2%, 39%, 1);
  --btn-style-2-border-hover: hsla(320, 2%, 30%, 1);
  --btn-style-3: hsla(320, 2%, 39%, 1);
  --btn-style-3-hover: hsla(320, 2%, 30%, 1);
  --btn-style-3-text: #ffffff;
  --btn-style-3-text-hover: #ffffff;
  --btn-style-3-border: 0;
  --btn-style-3-border-color: hsla(320, 2%, 39%, 1);
  --btn-style-3-border-hover: hsla(320, 2%, 30%, 1);
  --btn-style-4: hsla(320, 2%, 39%, 1);
  --btn-style-4-hover: hsla(320, 2%, 30%, 1);
  --btn-style-4-text: #ffffff;
  --btn-style-4-text-hover: #ffffff;
  --btn-style-4-border: 0;
  --btn-style-4-border-color: hsla(320, 2%, 39%, 1);
  --btn-style-4-border-hover: hsla(320, 2%, 30%, 1);
  --btn-style-5: hsla(320, 2%, 39%, 1);
  --btn-style-5-hover: hsla(320, 2%, 30%, 1);
  --btn-style-5-text: #ffffff;
  --btn-style-5-text-hover: #ffffff;
  --btn-style-5-border: 0;
  --btn-style-5-border-color: hsla(320, 2%, 39%, 1);
  --btn-style-5-border-hover: hsla(320, 2%, 30%, 1);
}

/* GENERAL */
a:hover {
  text-decoration: none;
}

body {
  color: var(--text-color);
  background: var(--body-color);
  left: 0;
  overflow-x: hidden;
  position: relative;
}
@media (max-width: 991px) {
  body {
    transition: all 0.2s ease-in-out;
  }
}
body.push-toright {
  left: -280px;
  overflow: hidden;
}

.wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}
main > section:not(.block-title) {
  margin-bottom: 80px;
}

.btn-primary {
  background-color: var(--primary);
  border-color: var(--primary-border-color);
  border-radius: var(--primary-border);
  color: var(--primary-text);
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  color: var(--primary-text-hover);
}
.btn-primary:disabled, .btn-primary.is-disabled {
  opacity: 0.5;
}
.btn-primary.style-1 {
  background-color: var(--btn-style-1);
  border-color: var(--btn-style-1-border-color);
  border-radius: var(--btn-style-1-border);
  color: var(--btn-style-1-text);
}
.btn-primary.style-1:hover, .btn-primary.style-1:focus, .btn-primary.style-1:not(:disabled):not(.disabled):active, .btn-primary.style-1:not(:disabled):not(.disabled).active {
  background-color: var(--btn-style-1-hover);
  border-color: var(--btn-style-1-hover);
  color: var(--btn-style-1-text-hover);
}
.btn-primary.style-1:disabled, .btn-primary.style-1.is-disabled {
  opacity: 0.5;
}
.btn-primary.style-2 {
  background-color: var(--btn-style-2);
  border-color: var(--btn-style-2-border-color);
  border-radius: var(--btn-style-2-border);
  color: var(--btn-style-2-text);
}
.btn-primary.style-2:hover, .btn-primary.style-2:focus, .btn-primary.style-2:not(:disabled):not(.disabled):active, .btn-primary.style-2:not(:disabled):not(.disabled).active {
  background-color: var(--btn-style-2-hover);
  border-color: var(--btn-style-2-hover);
  color: var(--btn-style-2-text-hover);
}
.btn-primary.style-2:disabled, .btn-primary.style-2.is-disabled {
  opacity: 0.5;
}
.btn-primary.style-3 {
  background-color: var(--btn-style-3);
  border-color: var(--btn-style-3-border-color);
  border-radius: var(--btn-style-3-border);
  color: var(--btn-style-3-text);
}
.btn-primary.style-3:hover, .btn-primary.style-3:focus, .btn-primary.style-3:not(:disabled):not(.disabled):active, .btn-primary.style-3:not(:disabled):not(.disabled).active {
  background-color: var(--btn-style-3-hover);
  border-color: var(--btn-style-3-hover);
  color: var(--btn-style-3-text-hover);
}
.btn-primary.style-3:disabled, .btn-primary.style-3.is-disabled {
  opacity: 0.5;
}
.btn-primary.style-4 {
  background-color: var(--btn-style-4);
  border-color: var(--btn-style-4-border-color);
  border-radius: var(--btn-style-4-border);
  color: var(--btn-style-4-text);
}
.btn-primary.style-4:hover, .btn-primary.style-4:focus, .btn-primary.style-4:not(:disabled):not(.disabled):active, .btn-primary.style-4:not(:disabled):not(.disabled).active {
  background-color: var(--btn-style-4-hover);
  border-color: var(--btn-style-4-hover);
  color: var(--btn-style-4-text-hover);
}
.btn-primary.style-4:disabled, .btn-primary.style-4.is-disabled {
  opacity: 0.5;
}
.btn-primary.style-5 {
  background-color: var(--btn-style-5);
  border-color: var(--btn-style-5-border-color);
  border-radius: var(--btn-style-5-border);
  color: var(--btn-style-5-text);
}
.btn-primary.style-5:hover, .btn-primary.style-5:focus, .btn-primary.style-5:not(:disabled):not(.disabled):active, .btn-primary.style-5:not(:disabled):not(.disabled).active {
  background-color: var(--btn-style-5-hover);
  border-color: var(--btn-style-5-hover);
  color: var(--btn-style-5-text-hover);
}
.btn-primary.style-5:disabled, .btn-primary.style-5.is-disabled {
  opacity: 0.5;
}

.btn-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary-border-color);
  border-radius: var(--secondary-border);
  color: var(--secondary-text);
}
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
  background-color: var(--secondary-hover);
  border-color: var(--secondary-hover);
  color: var(--secondary-text-hover);
}
.btn-secondary:disabled, .btn-secondary.is-disabled {
  opacity: 0.5;
}

.mask {
  --mask-color:0,0,0;
  background-color: rgba(var(--mask-color), 0.3);
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}
.mask.opacity-0 {
  background-color: rgba(var(--mask-color), 0);
}
.mask.opacity-30 {
  background-color: rgba(var(--mask-color), 0.3);
}
.mask.opacity-50 {
  background-color: rgba(var(--mask-color), 0.5);
}
.mask.opacity-70 {
  background-color: rgba(var(--mask-color), 0.7);
}

/* HEADER */
#menuToggle {
  text-decoration: none;
  margin: 10px 15px;
  border: 0;
  background: transparent;
}
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: var(--dark);
  border-radius: 3px;
  z-index: 1;
  transform-origin: 22px 2px;
}
#menuToggle.open {
  display: block;
  width: 40px;
  height: 32px;
  -webkit-touch-callout: none;
}
#menuToggle.open span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
}
#menuToggle.open span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
#menuToggle.open span:nth-last-child(2) {
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}
#menuToggle:focus, #menuToggle:active, #menuToggle:focus {
  outline: none;
  box-shadow: none;
}

.menu-mobile {
  background-color: var(--dark);
  position: fixed;
  top: 0;
  z-index: 10;
  height: 100%;
  width: 280px;
  right: -280px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
}
.menu-mobile .mwrapper {
  margin-top: var(--margin);
  height: calc(100vh - 75px);
  overflow-y: auto;
}
.menu-mobile ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0 20px;
}
.menu-mobile a {
  display: block;
  color: var(--light);
  padding: 6px 16px;
  text-decoration: none;
  position: relative;
  z-index: 11;
  text-transform: uppercase;
}
.menu-mobile.open {
  right: 0;
}
.menu-mobile .lang {
  display: flex;
  justify-content: center;
  margin-top: auto;
}
.menu-mobile .lang a {
  text-transform: uppercase;
  font-weight: 700;
}
.menu-mobile .lang a.active {
  color: var(--primary);
}

.header_1 {
  position: fixed;
  background: var(--light);
  width: 100%;
  z-index: 999;
}
.header_1 .navbar-toggler:focus,
.header_1 .navbar-toggler:active,
.header_1 .navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}
.header_1 + main > section:first-child, .header_1 + .breadcrumb-container {
  margin-top: var(--header-height);
}
.header_1 + .space {
  margin-top: 65px;
}

/* */
.breadcrumb {
  font-size: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  background: none;
}
.breadcrumb ul {
  margin: 0;
  padding: 0;
}

/* B02 */
.block-title {
  margin-bottom: var(--margin);
}

/* B03 */
@media (min-width: 960px) {
  .block-text .cols2 p {
    -moz-column-count: 2;
         column-count: 2;
  }
  .block-text .cols3 p {
    -moz-column-count: 3;
         column-count: 3;
  }
}
/* SEARCH RESULTS */
.block-search-results .results {
  font-size: 80%;
  color: var(--gray-dark);
  font-style: italic;
}
.block-search-results .search-results {
  list-style: none;
  margin: 0;
  padding: 0;
}
.block-search-results .search-results li {
  margin-bottom: var(--margin);
  color: var(--gray-dark);
}
.block-search-results .search-results li span {
  font-size: 12px;
  color: var(--gray);
  font-style: italic;
}
.block-search-results .search-results li h2 {
  color: var(--primary);
  margin-bottom: 0;
}

/* NEWSLETTER */
.block-32-newsletter label.error {
  display: none;
}
.block-32-newsletter .error {
  color: var(--danger);
}
.block-32-newsletter .valid {
  color: var(--success);
}
.block-32-newsletter .newsletter_subtitle {
  margin-bottom: 10px;
}
.block-32-newsletter .submit_newslettertext {
  margin-bottom: 0px;
}
.block-32-newsletter .spinner-border {
  width: 1rem;
  height: 1rem;
  border-width: 0.15rem;
}
.block-32-newsletter *:not(.btn) {
  color: inherit;
}
.block-32-newsletter .btn {
  margin-left: 1rem;
}
.block-32-newsletter .email_newsletter-msg {
  display: none;
}
.block-32-newsletter .nobutton {
  background: transparent no-repeat center center;
  border: 0;
  margin-left: -55px;
  width: 55px;
  background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='23.807' height='23.807' viewBox='0 0 23.807 23.807'%3E%3Cg transform='translate(-2.5 -2.293)'%3E%3Cpath d='M28.93,3,16.5,15.43' transform='translate(-3.33 0)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'%3E%3C/path%3E%3Cpath d='M25.6,3,17.69,25.6,13.17,15.43,3,10.91Z' transform='translate(0 0)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}
.block-32-newsletter .content .form-newsletter__body {
  display: grid;
  align-content: end;
}
@media (min-width: 960px) {
  .block-32-newsletter .content .form-newsletter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
  .block-32-newsletter .content .form-newsletter .form-check {
    grid-column: 2;
  }
  .block-32-newsletter .content .form-newsletter .email_newsletter-msg {
    grid-column: 1/3;
  }
}

/* BLOCK B06_IMAGE_TEXT HIGHTLIGHT CAROUSEL */
.block-06-image-text.block-06-highlight_carousel .slick-list,
.block-06-image-text.block-06-highlight_carousel .slick-track,
.block-06-image-text.block-06-highlight_carousel .slick-slide,
.block-06-image-text.block-06-highlight_carousel .slick-slide > div,
.block-06-image-text.block-06-highlight_carousel .item,
.block-06-image-text.block-06-highlight_carousel a {
  height: 100%;
}
.block-06-image-text.block-06-highlight_carousel .btn {
  margin-top: 1.5rem;
}

/* BLOCK LIST */
section.block-list [class^=col-], section.block-list .col {
  /* skin pages_categories 60_40 */
}
section.block-list [class^=col-] .date, section.block-list .col .date {
  color: var(--gray-dark);
  text-transform: uppercase;
}
section.block-list [class^=col-] > a, section.block-list .col > a {
  color: inherit;
}
section.block-list [class^=col-] .caption, section.block-list .col .caption {
  margin-top: 15px;
  margin-bottom: 15px;
}
section.block-list [class^=col-] .caption.items-center, section.block-list [class^=col-] .caption.items-center + .btn, section.block-list .col .caption.items-center, section.block-list .col .caption.items-center + .btn {
  text-align: center;
  justify-self: center;
}
section.block-list [class^=col-] .caption.items-right, section.block-list [class^=col-] .caption.items-right + .btn, section.block-list .col .caption.items-right, section.block-list .col .caption.items-right + .btn {
  text-align: right;
  justify-self: end;
}
section.block-list [class^=col-] .caption + .btn, section.block-list .col .caption + .btn {
  margin-top: auto;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
section.block-list [class^=col-].default > a, section.block-list .col.default > a {
  display: grid;
  grid-template-rows: min-content;
  height: 100%;
}
section.block-list [class^=col-].default img, section.block-list .col.default img {
  margin-bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
}
section.block-list [class^=col-].default:hover img, section.block-list .col.default:hover img {
  filter: brightness(1.2);
}
section.block-list [class^=col-].pl_60_40, section.block-list .col.pl_60_40 {
  margin-bottom: 3rem !important;
}
section.block-list [class^=col-].pl_60_40 .img-wrapper, section.block-list .col.pl_60_40 .img-wrapper {
  height: 320px;
}
section.block-list [class^=col-].pl_60_40 .img-wrapper img, section.block-list .col.pl_60_40 .img-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
section.block-list [class^=col-].pl_60_40 .title, section.block-list .col.pl_60_40 .title {
  margin-top: 2rem;
  margin-bottom: 1rem;
}
section.block-list [class^=col-].pl_60_40 .text, section.block-list .col.pl_60_40 .text {
  margin-bottom: 1rem;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
section.block-list .row:not(.no-gutters) [class^=col-] {
  margin-bottom: var(--margin);
}

.filter-form {
  position: absolute;
  background: var(--body-color);
  z-index: 2;
  top: 40px;
  left: 0;
  right: 0;
  padding: 15px;
  display: none;
}
.filter-form ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
}
.filter-form ul li {
  display: inline;
  margin: 10px 0;
}
.filter-form ul li input, .filter-form ul li img {
  display: none;
}
.filter-form ul li input:checked ~ label {
  box-shadow: inset 3px 0px 0px var(--primary);
}
.filter-form ul li label {
  border: 1px solid var(--primary);
  color: var(--primary);
  cursor: pointer;
  padding: 5px 40px !important;
  text-transform: uppercase;
  background: transparent !important;
  margin-bottom: 0;
  min-width: 100%;
  transition: all 0.3s ease-in-out;
}
.filter-form ul li label::after, .filter-form ul li label::before {
  display: none;
}
.filter-form ul li label:hover {
  background: var(--primary) !important;
  color: var(--body-color);
}
.filter-form__loading {
  stroke: var(--primary);
}
.filter-form + .filter-toggle {
  display: inline-block;
  border-radius: 50px;
  margin-bottom: 20px;
  cursor: default;
}
@media (min-width: 992px) {
  .filter-form {
    min-height: 77px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    position: relative;
    top: auto;
    padding: 0;
  }
  .filter-form ul {
    flex-direction: row;
    text-align: center;
  }
  .filter-form ul li {
    margin: 10px;
  }
  .filter-form ul li label {
    min-width: auto;
  }
  .filter-form + .filter-toggle {
    display: none;
  }
}

/* BLOCK B15_Cards-list */
.block-card-list .row [class^=col-] {
  margin-bottom: var(--margin);
}

/* BLOCK B19_CTA */
.block-cta .block-content {
  gap: 1rem;
}
.block-cta .block-content.border-cta {
  border: 1px solid;
}

/* BLOCK B33_TIMELINE */
section.block-33-timeline .timeline {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 15px;
}
section.block-33-timeline .timeline::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 110px;
  left: 45px;
  right: 45px;
  height: 1px;
  background-color: #E2E2E2;
}
section.block-33-timeline .slick-carousel .slick-next,
section.block-33-timeline .slick-carousel .slick-prev {
  z-index: 2;
  top: 110px;
  border: 1px solid #E2E2E2;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
}
section.block-33-timeline .slick-carousel .slick-next::before,
section.block-33-timeline .slick-carousel .slick-prev::before {
  display: none;
}
section.block-33-timeline .slick-carousel .slick-prev {
  left: -15px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGV2cm9uLWxlZnQiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMzIwIDUxMiIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLWNoZXZyb24tbGVmdCBmYS13LTEwIj4NCiAgICA8cGF0aCBjbGFzcz0iIiBmaWxsPSIjRTJFMkUyIiBkPSJNMzQuNTIgMjM5LjAzTDIyOC44NyA0NC42OWM5LjM3LTkuMzcgMjQuNTctOS4zNyAzMy45NCAwbDIyLjY3IDIyLjY3YzkuMzYgOS4zNiA5LjM3IDI0LjUyLjA0IDMzLjlMMTMxLjQ5IDI1NmwxNTQuMDIgMTU0Ljc1YzkuMzQgOS4zOCA5LjMyIDI0LjU0LS4wNCAzMy45bC0yMi42NyAyMi42N2MtOS4zNyA5LjM3LTI0LjU3IDkuMzctMzMuOTQgMEwzNC41MiAyNzIuOTdjLTkuMzctOS4zNy05LjM3LTI0LjU3IDAtMzMuOTR6Ij48L3BhdGg+DQo8L3N2Zz4=");
}
section.block-33-timeline .slick-carousel .slick-next {
  right: -15px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaGV2cm9uLXJpZ2h0IiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMyMCA1MTIiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaGV2cm9uLXJpZ2h0IGZhLXctMTAiPg0KICAgIDxwYXRoIGNsYXNzPSIiIGZpbGw9IiNFMkUyRTIiIGQ9Ik0yODUuNDc2IDI3Mi45NzFMOTEuMTMyIDQ2Ny4zMTRjLTkuMzczIDkuMzczLTI0LjU2OSA5LjM3My0zMy45NDEgMGwtMjIuNjY3LTIyLjY2N2MtOS4zNTctOS4zNTctOS4zNzUtMjQuNTIyLS4wNC0zMy45MDFMMTg4LjUwNSAyNTYgMzQuNDg0IDEwMS4yNTVjLTkuMzM1LTkuMzc5LTkuMzE3LTI0LjU0NC4wNC0zMy45MDFsMjIuNjY3LTIyLjY2N2M5LjM3My05LjM3MyAyNC41NjktOS4zNzMgMzMuOTQxIDBMMjg1LjQ3NSAyMzkuMDNjOS4zNzMgOS4zNzIgOS4zNzMgMjQuNTY4LjAwMSAzMy45NDF6Ij48L3BhdGg+DQo8L3N2Zz4=");
}
section.block-33-timeline .slick-carousel .slick-slide {
  padding: 0 50px;
}
section.block-33-timeline .slick-carousel .slick-slide .item_date {
  font-size: 42px;
  font-weight: 700;
}
section.block-33-timeline .slick-carousel .slick-slide .item_title {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
section.block-33-timeline .slick-carousel .slick-slide .item_image {
  max-width: 100%;
}
section.block-33-timeline .slick-carousel .slick-slide .item_line {
  z-index: 2;
  position: relative;
  left: 40px;
  height: 80px;
  border-left: 2px solid #E2E2E2;
  margin: 15px 0;
}
section.block-33-timeline .slick-carousel .slick-slide .item_line::after {
  content: "";
  position: absolute;
  top: -5px;
  left: -6px;
  height: 10px;
  width: 10px;
  background-color: #E2E2E2;
}

/* FORMS */
.label-before {
  line-height: 20px;
  font-size: 12px;
  top: -10px;
  background: #fff;
  padding: 0 6px;
  left: 9px;
}

.form-group.nolabel {
  position: relative;
}
.form-group.nolabel input + label,
.form-group.nolabel textarea + label {
  position: absolute;
  top: 0;
  left: 10px;
  transition: all 0.1s;
  opacity: 1;
  font-size: 12px;
  background: white;
  padding: 0 5px;
  transform: translateY(-50%);
}
.form-group.nolabel input:-moz-placeholder-shown + label, .form-group.nolabel textarea:-moz-placeholder-shown + label {
  opacity: 0;
  transform: translateY(100%);
}
.form-group.nolabel input:placeholder-shown + label,
.form-group.nolabel textarea:placeholder-shown + label {
  opacity: 0;
  transform: translateY(100%);
}

/* FOOTER */
.wrap > footer ul {
  list-style: none;
  padding: 0;
}

.bottom a {
  font-size: 12px;
  color: var(--gray);
}
.bottom a:not(:last-child)::after {
  content: "|";
  margin: 0 10px;
}
.bottom .copy a {
  opacity: 0.6;
}
.bottom .copy a:hover {
  opacity: 1;
}

.footer_1 {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 40px;
  background: var(--light);
}
.footer_1 .info {
  font-size: 14px;
}
.footer_1 + .bottom {
  background: var(--dark);
  color: var(--light);
}
.footer_1 a {
  font-size: 14px;
  color: inherit;
}
.footer_1 a:hover {
  text-decoration: none;
  color: var(--primary);
}
.footer_1 .footer-social a {
  font-size: 22px;
  margin-top: 20px;
  margin-right: 15px;
}
.footer_1 h4 {
  font-size: 20px;
}
@media (max-width: 998px) {
  .footer_1 h4 {
    margin-top: var(--margin);
  }
}

.footer_2 {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 40px;
  background: var(--light);
}
.footer_2 a {
  font-size: 14px;
  color: inherit;
}
.footer_2 a:hover {
  text-decoration: none;
  color: var(--primary);
}
.footer_2 .col {
  margin-bottom: 40px;
}
.footer_2 .footer-social a {
  border-radius: 50px;
  background-color: var(--gray-dark);
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.footer_2 h4 {
  font-size: 20px;
}
@media (max-width: 998px) {
  .footer_2 h4 {
    margin-top: var(--margin);
  }
}
.footer_2 address {
  font-size: 14px;
}

@media (min-width: 992px) {
  .col-md-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-md-2-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-md-3-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-md-4-5 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-md-5-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-lg-2-5 {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-lg-3-5 {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-lg-4-5 {
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-lg-5-5 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}