.layout-formacao header .dropdown-menu {
  -moz-column-count: 1;
       column-count: 1;
  top: 100% !important;
  width: -moz-fit-content;
  width: fit-content;
}
.layout-formacao .compensa-header-formacao {
  margin-top: 100px;
}
.layout-formacao .breadcrumb {
  padding: 0;
  background-color: #fff;
}
.layout-formacao .topnav li a {
  margin-top: 8px;
  margin-left: 10px;
}
.layout-formacao .logo-img {
  max-width: 325px;
  box-shadow: none !important;
}
.layout-formacao .block-cta-video .row {
  background-color: #0B576F;
}
.layout-formacao .plyr__poster {
  background-size: cover;
}
.layout-formacao .plyr--stopped .plyr__controls {
  display: none;
}
.layout-formacao .testemunho-wrapper-video {
  background-color: #f5f5f5;
}
.layout-formacao .testemunho-wrapper-video .slick-dotted.slick-slider {
  margin-bottom: 0px;
}
.layout-formacao .testemunho-wrapper-video .slick-dots {
  position: absolute;
  bottom: 20px;
  display: block;
  width: 41.666667%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.layout-formacao .titulo-lista-curso {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  width: 85%;
  z-index: 99;
}
.layout-formacao .curso-background {
  height: 100px;
  width: 100%;
  text-align: center;
  transition: all 0.2s ease-in-out;
}
.layout-formacao .curso-lista-link:hover .curso-background {
  transform: scale(1.3);
}
.layout-formacao .data-curso-lista {
  color: #0B576F;
  font-size: 12px;
}
.layout-formacao .logo-cert-curso {
  max-width: 160px;
}
.layout-formacao .header-normal {
  text-align: left;
  border-bottom: 0px solid #ccc;
  margin: 10px 0 20px;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 0;
}
.layout-formacao #share-icon {
  cursor: pointer;
}
.layout-formacao #share-wrap {
  position: absolute;
  right: 0px;
  top: 26px;
  display: none;
  z-index: 999;
}
.layout-formacao .share-curso .svg-inline--fa {
  font-size: 18px;
}
.layout-formacao.aep-formacao .breadcrumb-container {
  display: none;
}
.layout-formacao .rightcol-cursos {
  border-left: 6px solid #F8F8F8;
}
.layout-formacao .font-12 {
  font-size: 12px;
}
.layout-formacao .share-curso {
  position: absolute;
  right: 15px;
  background-color: #fff;
  top: -25px;
  padding-left: 15px;
}
.layout-formacao .icon-tipo-curso {
  width: 50px;
  border: 5px solid #fff;
  right: 15px;
  bottom: -15px;
  z-index: 9999;
}
.layout-formacao .icon-tipo-curso.icon-detalhe {
  right: 40px;
}
.layout-formacao .tipos-de-cursos .custom-select {
  color: #009ca6;
  font-weight: 700;
  border-radius: 0px;
  padding: 5px 30px 5px 20px;
  margin-top: -7px;
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAoJJREFUaEPt1kvIjmkYB/AfMjmELLARK8ZKUhZqnC0cazJI2TiUGposiLIhJSkrzYgUSSIWQ0nkfFiMYjNqZmNiQSxEiYYIXXV9Fvr63ud97/elr5578z7Pe1/X//r/r/9zH/ro5aNPL+evFvC9HawdqB0o7ED9CRU2sDi9dqC4hYUAtQOFDSxOrx0obmEhQFUH+uIITuNcYc1G6YvxC9bgY6PgqgL+wHr8j/m40Qi4xflZOI8B2I8NjXCqCtiGXQn2CnNwtxF4k/NTcAVDMi9q7m6EUVVA4OzBlgR8jhn4p1GBivPjcQsjM34fNlbJbUZAxB7AugR+gml4WKVQDzFjknz8xjiK1fhUBbcZAYEXi/k4ViT4fyniaZVi3cREx2/ix5z7E8vxoSpeswICtz/OYEEWuY+ZeFG1aMYNw3VMyvfLWIR3zeC0IiDwB+ICpmexO5iL1xWLD8JF/JTxf2X+m4r5X8JaFRAAQ3ENkxPtKhbibQMSP+As5mXc3+ngy2bJR3yJgMgfkd/whCwexJb28A33wwksy/gHuYaetUK+HQICYzRuY2ySOIZV3Zyi0axDWJtxj5P8o1bJt0tA4IzLrXBUkvkdv31FbC825X9xjsT6+beEfDsFBNbE3FWGJ6nt2JnPOxDvMeIkn417peTbLSDwpuISBie5zbmow5EYcZeKxRt7f1tG6SLujkScD3FOxHnRdZpGnff4OS9rbSHfCQe6iC3BKcSuEyOuxStxsm3ME6gTDnRxjJ3ocL78ioPtJt9JB7q4bs2HuMl2ZHTSgY4Q/hq0FvBN2txDkdqB2oHCDtSfUGEDi9NrB4pbWAhQO1DYwOL02oHiFhYC9HoHPgOdmFYxRYKifQAAAABJRU5ErkJggg==) right 5px center/15px 15px no-repeat;
}
.layout-formacao #tipos-cursos .spinner-border {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
  margin-top: -10px;
}
.layout-formacao footer .footer02 {
  background-image: none;
  background-color: #DBDBDB;
  color: #333;
  font-size: 12px;
}
.layout-formacao footer .footer02 .fake-space {
  height: 41.8px;
}
.layout-formacao footer .footer02 .footer-logo-img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 126px;
  max-width: 25%;
}

.slider-historia .slider-nav {
  height: 40px;
  line-height: 36px;
  width: 40px;
  text-align: center;
  position: absolute;
  z-index: 99;
  color: #fff !important;
  font-size: 28px;
  margin: 5px;
  padding: 2px 0px;
  background: #009ca6;
  display: inline-block;
  cursor: pointer;
  border-radius: 0px;
}

.slider-historia .prev, .slider-historia .next {
  top: 25%;
}

.slider-historia .prev {
  left: 5% !important;
}

.slider-historia .next {
  right: 5% !important;
}

.slider-aep-caption {
  background: transparent;
}

.campanha {
  background-color: #BA920B;
  color: #fff;
  padding: 8px 25px;
  font-weight: 700;
}

.campanha-texto {
  color: #BA920B;
  font-weight: 600;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .layout-formacao .testemunho-wrapper-video .slick-dots {
    bottom: -25px;
    width: 100%;
  }
  .layout-formacao .share-curso {
    top: -38px;
    display: flex;
  }
  .accordion .card .card-body img {
    max-width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
  .section-header {
    line-height: unset !important;
  }
  .layout-formacao .titulo-lista-curso {
    font-size: 12px;
  }
  .textoprogramasesp img, .table-responsive img {
    max-width: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    height: auto !important;
  }
  .cursos-cta a {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  .layout-formacao .compensa-header-formacao {
    margin-top: 75px;
  }
}