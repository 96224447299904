@keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }
  to {
    transform: translate(0, 0);
  }
}
.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  transition: opacity 0.3s ease-out;
}

.modal-video-close {
  opacity: 0;
}

.modal-video-close .modal-video-movie-wrap {
  transform: translate(0, 100px);
}

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (orientation: landscape) {
  .modal-video-inner {
    padding: 10px 60px;
    box-sizing: border-box;
  }
}
.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}

@media (orientation: landscape) {
  .modal-video-close-btn {
    top: 0;
    right: -45px;
  }
}
.modal-video-close-btn:before {
  transform: rotate(45deg);
}

.modal-video-close-btn:after {
  transform: rotate(-45deg);
}

.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}

:root {
  --sal-duration: 0.5s;
}

html {
  scroll-behavior: smooth;
}

:target:before {
  content: "";
  display: block;
  height: 100px;
  margin: -100px 0 0;
}

.adminbar {
  display: none !important;
}

/* CORES */
.mm-menu {
  --mm-color-border: #dae3e7;
}

.mm-navbar {
  --mm-color-background: #009ca6;
  --mm-color-text-dimmed: #fff;
  --mm-color-button: #fff;
}

.mm-panel {
  --mm-color-background: #f5f7f8;
  --mm-color-text: #222;
  --mm-color-button: #009ca6;
}

.mburger {
  --mb-button-size: 40px;
  --mb-bar-height: 2px;
  --mb-bar-spacing: 7px;
}

.mburger b {
  background: #005670 !important;
}

.mm-listitem:after {
  left: 0px !important;
}

.mm-listitem__btn:not(.mm-listitem__text) {
  border-left-width: 0px !important;
}

.mm-menu a,
.mm-menu a:active,
.mm-menu a:hover,
.mm-menu a:link,
.mm-menu a:visited {
  color: inherit;
  font-size: 14px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
  padding: 0;
}

.menuprincipal {
  align-items: start;
  height: 48px;
}

.navbar-expand-lg {
  padding: 0 15px;
}

ul.menuprincipal li ul {
  padding: 5px 0;
}

.dropdown-menu {
  border: 0px;
  display: unset;
  border-radius: 0px;
}

.dropdown-item {
  display: block;
  width: auto;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #005670;
  text-align: inherit;
  white-space: normal;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #009ca6;
  background-color: transparent;
}

.minimenu {
  position: absolute;
  top: -13px;
  right: 20px;
}

.home.compensa-header {
  margin-top: 95px;
}

.footer-links {
  list-style: none;
  padding-left: 0;
  margin-bottom: 15px;
}

footer {
  padding-bottom: 0px;
}

.block-slideshow .slick-prev:before,
.block-slideshow .slick-next:before {
  font-size: 36px;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.5;
  color: #000;
}

.block-accordion .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0px;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.block-accordion .card {
  border: 0px;
  border-bottom: 1px solid #ddd !important;
  border-radius: 0;
  margin-bottom: 0px;
}

.accordion-header a {
  color: #009ca6 !important;
}

.block-accordion .card-header.open {
  background-color: #009ca6;
  color: #fff;
}

.block-accordion .card-header.open a {
  color: #fff !important;
}

/* 5 Columns */
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-lg-15 {
  flex: 0 0 20%;
  max-width: 20%;
}

.dark-header {
  transition: all 0.3s ease-in-out;
  background-color: #303030;
}

.layout-golf .nav-link {
  transition: all 0.3s ease-in-out;
  color: #fff;
}

.dark-header .nav-link {
  transition: all 0.3s ease-in-out;
  color: #fff;
}

.layout-golf .nav-link:hover {
  opacity: 0.8;
}

.dark-header .nav-link:hover {
  color: #0b5f97;
}

.texto-1-gal {
  transition: all 0.5s ease-in-out;
  font-size: 32px;
  color: #fff;
  font-weight: 500;
  line-height: 32px;
}

.texto-2-gal {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  color: #fff;
}

.img-galeria {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.img-galeria:hover .texto-2-gal {
  opacity: 1;
}

.img-galeria:hover .texto-1-gal {
  opacity: 0;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, .8)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-color: transparent;
}

.custom-toggler.navbar-toggler:hover .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(11,95,151, .8)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}
@media (min-width: 992px) {
  .col-md-15 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
  .layout-golf .container {
    max-width: 1300px;
  }
}
.slick-dots li button:before {
  font-size: 12px;
}

.btn-cta {
  display: block;
  max-width: 280px;
  margin: 0 auto;
}

.block-cta-aep {
  margin-top: -80px;
  padding: 0 0 50px 0;
}

.block-text-titulo h2.titulo-paginas {
  font-size: 14px !important;
  line-height: 22px;
}

ul.social {
  list-style: none;
  padding: 0;
}

ul.social li a,
.fa-linkedin {
  font-size: 18px;
}

label.error {
  color: #c00;
  font-size: 11px;
  font-weight: normal;
  width: 100%;
  margin-top: 10px;
}

iframe {
  border: 0px;
}

.associado-wrapper ul {
  padding: 0;
  list-style: none;
}

.layout-golf .carousel-caption h3 {
  background-color: transparent;
  color: #ffffff;
  font-size: 50px;
  line-height: 70px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  text-transform: none;
  padding: 0;
}

.layout-golf .carousel-caption {
  width: 70%;
  left: 2%;
  top: 25%;
  text-align: left !important;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

.layout-golf .banner-subtitle {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}

#back_to_top {
  color: #cdcdcd;
  height: auto;
  position: fixed;
  bottom: 65px;
  margin: 0;
  z-index: 10000;
  transition: all 0.3s ease 0s;
  right: 25px;
  visibility: hidden;
  opacity: 0;
}

#back_to_top > span {
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-decoration: none;
  border-radius: 52px;
  border: 2px solid #e8e8e8;
  background: 0 0;
  text-align: center;
  font-size: 22px;
  background-color: #fff;
}

#back_to_top span i {
  color: #b0b0b0;
  line-height: 52px;
  font-weight: 900;
}

#back_to_top.on {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  right: 25px;
}

#sobre {
  border-top: 1px solid #81d742;
  padding-top: 60px;
}

.texto-azul {
  color: #005670;
}

.form-control {
  background: #fff;
  color: #333;
  font-weight: 500 !important;
  font-size: 12px;
}

.js-participant {
  border: 1px solid #e7e7e7;
  padding: 10px 0;
  margin-bottom: 20px;
}

.js-participant-name {
  font-weight: bold;
  font-size: 16px;
  margin-left: 15px;
  padding-bottom: 10px;
  display: block;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.block-banner-carousel .slick-slide {
  margin: 0 15px;
}

.block-banner-carousel .slick-list {
  margin: 0 -15px;
}

.font-16 {
  font-size: 16px !important;
}

.w-lg-50 {
  width: 50%;
}

.row:not(.no-gutters) [class^=col-] {
  margin-bottom: unset;
}

.header_1 {
  background-color: #fff;
}

.js-modal-btn {
  cursor: pointer;
}

.accordion .card-body {
  padding-top: 1.25rem;
}

.block-text img {
  max-width: 100% !important;
  height: auto !important;
}

.download-doc a {
  padding: 10px 40px;
  background-color: wheat;
  font-weight: 600;
}

.alert-danger-val {
  background-color: #ff5c75;
  border-color: #ff5c75;
  color: #fff;
}

.register_interests {
  -moz-column-count: 2;
       column-count: 2;
}

.datatable-checkbox input[type=checkbox] {
  visibility: visible;
  position: unset;
  margin: 6px 0px 0px 0px;
}

.alert-saved-success {
  color: #3c763d !important;
  background-color: #dff0d8 !important;
}

#form_datatable tbody tr:hover td {
  background-color: #c9ff23 !important;
}

#form_datatable tbody tr td {
  padding-top: 3px;
  padding-bottom: 3px;
  vertical-align: center;
}

.list-submitted-name {
  padding-top: 8px;
}

.list-submitted-pdf {
  font-size: 24px;
}

.aep-logo-bow {
  height: 50px;
}

.required-inputs-bg {
  background-color: #f7f7f7 !important;
}

@media (max-width: 768px) {
  .w-lg-50 {
    width: 100%;
  }
  .btn-cta {
    margin-bottom: 40px;
  }
  .tituloalternado {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .section-header span {
    padding: 0 10px 0 0;
  }
  .home.compensa-header {
    margin-top: 60px;
  }
  .layout-golf .nav-item {
    border-bottom: 1px solid #424242;
  }
  .layout-golf .nav-item:last-child {
    border-bottom: 0px;
  }
  .layout-golf .nav-link {
    color: #b7b7b7;
  }
  .layout-golf .navbar {
    background-color: #303030;
  }
  .layout-golf .carousel-caption h3 {
    background-color: transparent;
    color: #ffffff;
    font-size: 40px;
    line-height: 50px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-transform: none;
    padding: 0;
  }
  .layout-golf .banner-subtitle {
    font-size: 20px !important;
  }
  .layout-golf .nav-link:hover {
    color: #0b5f97 !important;
  }
  .dark-header .nav-link:hover {
    color: #0b5f97 !important;
  }
  .col-lg-15 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.block-form .select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 0px;
  height: 35px;
}
.block-form .select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-top: 0px;
}
.block-form .js-bow-eligibility-label {
  max-width: 1px;
  padding-left: 0px;
}
.block-form .js-bow-eligibility-label + div {
  font-size: 12px;
}
.block-form .std_formtext .js-bow-year,
.block-form .std_formtext .js-bow-yearplus {
  padding: 2px 3px;
  background-color: #e5e5e5;
  color: #000;
  border-radius: 3px;
  font-weight: 600;
}
.block-form .btn-save-later {
  background-color: #2a8bb0;
  color: #fff;
}
.block-form .btn-save-later:hover {
  background-color: #005670;
}