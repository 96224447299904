.left {float:left;}
.right {float:right;}
.clearfix {clear:both;}
.nopadding {padding-left: 0 !important; padding-right:0 !important;}
.margin-grid{margin-top:15px; margin-bottom:30px}
.margin-grid-bottom{margin-bottom:30px}
.shadow-bottom {-webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3); -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3); box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.3);}
.shadow {-webkit-box-shadow: 1px 1px 9px 0px rgba(50, 50, 50, 0.3); -moz-box-shadow: 1px 1px 9px 0px rgba(50, 50, 50, 0.3); box-shadow:1px 1px 9px 0px rgba(50, 50, 50, 0.3);}
.bradius6 {-webkit-border-radius: 6px !important; -moz-border-radius: 6px !important; border-radius: 6px !important; }
.bradius6-bottom {-webkit-border-bottom-right-radius: 6px; -webkit-border-bottom-left-radius: 6px; -moz-border-radius-bottomright: 6px; -moz-border-radius-bottomleft: 6px; border-bottom-right-radius: 6px; border-bottom-left-radius: 6px;}
.bradius6-left {-webkit-border-top-left-radius: 6px; -webkit-border-bottom-left-radius: 6px; -moz-border-radius-topleft: 6px; -moz-border-radius-bottomleft: 6px; border-top-left-radius: 6px; border-bottom-left-radius: 6px;}
.transform50XY {top:50%; left:50%; transform:translateY(-50%) translateX(-50%); -moz-transform:translateY(-50%) translateX(-50%); -webkit-transform:translateY(-50%) translateX(-50%); -o-transform:translateY(-50%) translateX(-50%); -ms-transform:translateY(-50%) translateX(-50%);}
.transform50Y {top:50%; transform:translateY(-50%); -moz-transform:translateY(-50%); -webkit-transform:translateY(-50%); -o-transform:translateY(-50%); -ms-transform:translateY(-50%);}
.transform50X {left:50%; transform:translateX(-50%); -moz-transform:translateX(-50%); -webkit-transform:translateX(-50%); -o-transform:translateX(-50%); -ms-transform:translateX(-50%);}
.transicao {-webkit-transition: all .3s ease-in-out;  -moz-transition: all .3s ease-in-out; -o-transition: all .3s ease-in-out; -ms-transition: all .3s ease-in-out; transition: all .3s ease-in-out; }
.Open Sans-font {font-family: 'Open Sans', sans-serif;}
.transicaoheader {-webkit-transition: all .9s ease-in-out;  -moz-transition: all .9s ease-in-out; -o-transition: all .9s ease-in-out; -ms-transition: all .9s ease-in-out; transition: all .9s ease-in-out; }
.form-label {margin-bottom: 10px;}
.form-control {font-size: 11px;color:#7e7e7e; padding:4px 10px; border-radius: 0; box-shadow:none; background: #fff; border: 1px solid #dfdfdf; height: 35px;}
textarea:focus,
textarea.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
select:focus,
select.form-control:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {border-color:#adacac !important; box-shadow: none !important; outline: 0 none;}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {.form-control {-webkit-appearance:none;} }
.form-control-feedback {top: 0px;right: 13px; width: 14px; height: 14px; line-height: 28px;}
.has-feedback label ~ .form-control-feedback {top: 21px; right:5px }
.form-label {margin-bottom: 6px;font-size:14px;}
.loading {width:18px; height: 15px; background:url("/images/loader.gif") no-repeat; display: none;}
.fullimgbg {background-position: center center; background-repeat: no-repeat; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover;}
.checkbox, .radio {width: auto;}
label.error {color:red;}
.overflowhidden{overflow:hidden;}
.border-caption {border:1px solid #bfbfbf; border-top:none}

#area .login {width: 100%}
.social-login {width: 100%; margin: 25px auto 0px auto; position: relative;}
.social-login li {width: calc(50% - 5px);text-align: center; border:0;float: left;  padding:10px 20px; margin-right: 10px; cursor: pointer;}
.social-login li:hover {color:#fff;background: #000}
.social-login li i {margin-right: 5px;}
.social-login li:last-child {margin-right: 0;}
.social-login .facebook-large {background: #3b5998; color:#fff;}
.social-login .facebook-large:hover {background: #284786}
.social-login .google-large {background: #DD4C3B; color:#fff;}
.social-login .google-large:hover {background: #a9241a;}

.separator-connect {font-size: 13px;text-align: center; text-transform: uppercase;  margin-top: 30px; position: relative;}
.separator-connect:after {content:''; height: 1px; background: rgba(230,60,47,0.2); position: absolute; top:8px; left: 15px; right: 15px; }
.separator-connect span {padding: 5px 10px; background:#fff; position: relative; z-index: 2; }

#InputHidden {display: none;}
.alert {font-size: 14px; text-align: center;clear:both;}
.margin-header{padding-top:100px;}
#modal-termos .modal-content {padding-top:10px;}
.modal .modal-content > button {position: absolute; top: 10px; right: 15px;z-index: 10; color: #1a4a7b; opacity: 1}

.nav-tabs > li > a {border-radius: 0;}
.btn-default:active, .btn-default.active, .btn-default:active:focus, .btn-default.active:focus {background-color: #ffffff; color:#ffffff;}

.btn {font-size:13px;border-radius: 0;padding:8px 12px; letter-spacing: 2;}
.btn.disabled {cursor: not-allowed; border: 1px solid #666; color:#666; pointer-events:inherit;}
.btn-primary {background:#40b3df !important; border-color:#40b3df !important; color:#fff !important;}
.btn-primary:hover {background:#2a8bb0 !important; color:#ffffff !important; border-color:#2a8bb0 !important}
.btn-primary:focus, .btn-primary.focus, .btn-primary:not(:disabled):not(.disabled):active:focus {-webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none;}
.btn-secondary,.btn-secondary:link {background:transparent !important; border-color:#004784 !important; color:#004784 !important; text-transform: uppercase;}
.btn-secondary:hover {background:#c2d3bf !important;}

.btn-default{color:#143a61}
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {width: 100%;}

.btn-danger:focus, .btn-danger.focus {box-shadow: none;}
/* BREADCRUMB */
.breadcrumb-wrapper {float:right;margin-bottom: 15px;text-align: right;}
.breadcrumb-wrapper i {color:#40b3df; margin:0 5px;}
.breadcrumb-wrapper .breadcrumb-content {padding-top:4px;text-transform:uppercase;color:#666;}
.breadcrumb-wrapper .breadcrumb-content a {color:#666;}
.breadcrumb-wrapper .breadcrumb-content a:hover {color:#000;}

/* FONTS */
h1 {color:#333333; margin-bottom: 20px; font-size: 23px;  font-weight: 300;padding-bottom: 10px; border-bottom: 1px solid #40b3df; margin-bottom: 15px; display: inline-block;}
h1:after {content: ''; clear:both;}
h2 {font-size:18px;font-weight: bold;color:#000;}
h3 {font-size:15px;  padding-bottom: 10px; margin-bottom: 15px; display: inline-block;}
h3.h3-registo {margin-top: 30px; font-weight: 600; text-transform: uppercase;}
b, strong {font-weight: bold;}
p {line-height: 21px; font-size: 14px; word-wrap:break-word;}

a:link, a:hover, a:visited, a:active{ color:#232323; text-decoration:none;}
a:hover {color:#666;}
a.disabled, a.disabled:hover, a.disabled:focus {color: #999 !important; cursor: not-allowed; }

.font12 {font-size: 12px;}
.font14 {font-size: 14px;}
.font15 {font-size: 15px;}
.font16 {font-size: 16px;}
.font25 {font-size: 25px;}
.fontLight {font-weight: 300;}
.txtAzul {text-transform: uppercase; color:#40b3df;}
.txtWhite {color:#fff;}
.txtshadow {text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);}
.txtUppercase {text-transform: uppercase;}

/* CARRINHO QUANTIDADES */
.quantity-wrapper {position: relative;width: 55%;}
.btn-quantity-wrapper {position:absolute;top:0;width: auto; right: 0;z-index:2;}
.quantityinput {padding-right: 70px;border: 1px solid #cbcbcb;height: 33px;padding-left:5px; font-size: 15px; width: 100%; text-align: right;border-radius: 0}
.btn-quantity {border: 1px solid #cbcbcb; color: #40b3df; margin-left: 1px; margin-top:0; background: #fff;width: 30px; height: 33px; float: left; line-height: 33px; font-size: 10px;text-align:center;vertical-align:middle;cursor: pointer;-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;}
.btn-quantity:active {background:#0198d1;}
.btn-quantity:active i {color:#fff;}

/* WINDOW ALERT */
#windowAlertModal {z-index: 9999999999;}
#windowAlertModal .modal-header .close {margin-top: -2px;position: absolute;top: 10px;right: 10px;}
#windowAlertModal .modal-header {padding: 30px 20px 10px;border-bottom: 0;min-height: 16.4286px; color: #FFF;}
#windowAlertModal .modal-header h3 {color:#40b3df; font-size: 20px;text-align: center; font-weight: bold;}
#windowAlertModal .modal-content {border-radius: 0; border:1px solid #000;}
#windowAlertModal .modal-content p {font-size: 14px;color:#A7A9AC; text-align: center;}
#windowAlertModal .modal-footer {padding:15px;}
#windowAlertModal .modal-footer button {width: 100%; height: 40px;}
#windowAlertModal .modal-body {padding: 10px 20px 30px;}
#windowAlertModal .btn {background-color: #40b3df;margin-left: 0;}
#windowAlertModal .btn-danger {background-color: #40b3df; border:0;}

/* WINDOW CONFIRM */
#windowConfirmModal {z-index: 9999999999;}
#windowConfirmModal .modal-header .close {margin-top: -2px;position: absolute;top: 10px;right: 10px;}
#windowConfirmModal .modal-header {padding: 30px 20px 10px;border-bottom: 0;min-height: 16.4286px; color: #FFF;}
#windowConfirmModal .modal-header h3 {color:#000; font-size: 20px;text-align: center; font-weight: bold;}
#windowConfirmModal .modal-content {border-radius: 0; border:1px solid #40b3df;}
#windowConfirmModal .modal-content p {font-size: 14px;color:#A7A9AC; text-align: center;}
#windowConfirmModal .modal-footer {padding:15px;}
#windowConfirmModal .modal-footer button {width: 100%; height: 40px;}
#windowConfirmModal .modal-body {padding: 10px 20px 30px;}
#windowConfirmModal .btn {background-color: #40b3df;margin-left: 0;}
#windowConfirmModal .btn-danger {background-color: #40b3df; border:0;}
#windowConfirmModal .btn-primary {background-color: #40b3df; border:0;}

.modal{text-align:center;padding:0!important;}
.modal-body {padding: 2rem 1rem;}
.modal .modal-content {padding-top:15px; border:0; }
.modal .modal-content>button.close {position: absolute; top: 10px; right: 10px;z-index: 10}
.modal:before{content:'';display:inline-block;height:100%;vertical-align:middle;margin-right:-4px;}
.modal-dialog{display:inline-block;text-align:left;vertical-align:middle;}
.modal-body h1, .modal-body h2{margin-top: 0; margin-bottom: 20px; /*font-size: 21px; */text-transform: uppercase}
.modal .modal-footer {border-color: rgba(0,44,90,.2);}
.modal h5.modal-title {font-size: 1.5rem;}
.modal#mensagens .modal-body {font-size: 14px}

/* checkbox + radio */
.checkbox, .radio {width: auto;}
input[type=checkbox], input[type=radio]{visibility:hidden;position:absolute;}
label.checkbox{cursor:pointer; min-height:23px; font-weight: 400px; padding-right:32px; padding-left: 32px; overflow:visible;display:inline-block;position:relative; padding-top:3px !important; font-size: 15px; line-height: 15px; font-weight: normal}
label.checkbox:before{background:#fff;content:'';position:absolute;top:0px;left:0;width:24px;height:24px;border:1px solid #cbcbcb;}
label.checkbox.error:before {border:1px solid #cc0000;}
label.checkbox:after{opacity:0;content:'';position:absolute;width:11px;height:6px;background:transparent;top:8px;left:7px;border:3px solid #40b3df;border-top:none;border-right:none;-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);-o-transform:rotate(-45deg);-ms-transform:rotate(-45deg);transform:rotate(-45deg);}
input[type=checkbox]:checked + label:after{opacity: 1; }
label.checkbox.unchecked:after {opacity: 0;}

label.radio{cursor:pointer; min-height:23px; text-indent: 32px;padding-right:32px;overflow:visible;display:inline-block;position:relative;font-size: 13px; font-weight: normal}
label.radio:before{background:#fff;content:'';position:absolute;top:0px;left:0;width:24px;height:24px;border:1px solid #cbcbcb;-webkit-border-radius: 15px; -ms-border-radius:15px; -moz-border-radius: 15px; border-radius: 15px;}
label.radio.error:before {border:1px solid #cc0000;}
label.radio:after{opacity:0;content:'';position:absolute;width:11px;height:6px;background:transparent;top:8px;left:7px;border:3px solid #1a4a7b;border-top:none;border-right:none;-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);-o-transform:rotate(-45deg);-ms-transform:rotate(-45deg);transform:rotate(-45deg);}
input[type=radio]:checked + label:after {opacity: 1; }
label.radio.unchecked:after {opacity: 0;}



/*USER MENU*/

.netusers-menu li ul {margin-left: 20px; margin-top: 5px}
a.user-cart-top {color:#000;padding: 0px 3px 5px;}
a.user-cart-top:hover {color:#666;}
a.user-cart-top i {float:left;vertical-align: middle;font-size:16px; margin-right:6px; color:#333;}
.user-cart-top-content {float:left;text-transform:uppercase}
.user-box-login-bts.open .pestana_area {border-bottom:2px solid #40b3df;}
.user-cart-top-wrapper.open .user-cart-top {border-bottom: 2px solid #40b3df}
.user-wishlist {margin-left:10px;padding-left:10px; border-left:1px solid #bbb;}
.btlogout {text-transform: uppercase;}
.netusers-menu li {position: relative; margin-bottom:3px;}
.netusers-menu > li > a {color:#666;font-weight: 700;}
.netusers-menu > li > a:hover {color:#000;;}
.netusers-menu li .fa-angle-right{position: absolute; top: 3px; left: -12px}
.netusers-menu a.active {font-weight: 700}
.netusers-menu a.link-off {pointer-events: none; color:#c7c7c7;}


/*LANGS*/
#lang {position: relative; z-index:5; }
#lang i {margin-left: 3px; color:inherit; vertical-align: middle; font-size: 10px}
#lang .fa-chevron-down {margin-top: 0px;}
#lang > a {font-size:12px;cursor: pointer; line-height: 10px;color:#808080;}
#lang > a:hover {font-size:12px !important;color:#40b3df;}
#lang ul {border:1px solid #40b3df;top:20px; margin-top:5px; display:none;transform-origin: 50% 0px 0px; transform-style: preserve-3d; opacity: 0; transform: scale(1, 1) rotateX(90deg); transition: all 0.2s ease 0s;}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {#lang ul {display:none;} }
#lang ul li:hover a {background:#40b3df; color:#fff;}
#lang .arrow{position:absolute;top: 18px;right: 23px;display:none; width:9px; height:4px;float:left};
#lang.show .arrow {display: block}
#lang.show ul {opacity: 1;transform: scale(1, 1) rotateX(0deg); display: block}
#lang a {display: inline-block; width: 100%; padding:2px 7px;}
#lang .dropdown-menu {min-width: 6rem; padding: 5px;}

.minimenu > .redes {float: left; border-right: 1px solid #ccc;}
.minimenu > .redes:last-child{border:none; padding-right:0 !important;}
.minimenu > .redes a {color:#808080;}
.minimenu > .redes a:hover {color:#40b3df;}
.social-mobile {bottom: 0;left: 0;right: 0;}
.social-mobile a:nth-child(1){border-left: none}
.social-mobile a{width: 25%;border-top:1px solid #cbcbcb;border-left:1px solid #cbcbcb;display: inline-block; text-align: center;padding: 10px 0}


/*SEARCH*/
.search-list-results li {width: 100%;clear:both; padding:15px; float:left;margin-bottom: 15px; border-bottom:1px solid #ebebeb;}
.search-list-results li:hover {background:#ebebeb;}
.search-list-results li img {margin-right: 15px;}
.search-list-results li h2 {margin-bottom: 10px;}
.search-list-results li p {width: 80%;}
.search-list-results li .product-price {float: right;}

/*BREADCRUMB*/
#breadcrumb {float:right;margin-bottom: 20px; text-transform: uppercase;}
#breadcrumb i {padding:0 6px; font-size:9px;color:#999;}
#breadcrumb i:last-child {display: none;}
#breadcrumb a {font-size:10px;color:#999;}
#breadcrumb a:hover {color:#555;}

/*PAGINATION*/
.paginacao {width:100%; background:#fff; margin-top:15px; margin-bottom:15px; padding-top:5px;}
.n-total-prod {line-height: 40px;vertical-align: middle; font-size: 12px; text-transform: uppercase;}
.n-total-prod b {font-weight: 600; color:#000;}
.label-mostrar {line-height:35px; font-size:12px; vertical-align:middle; margin:0 10px;}
.pagination-wrapper {background:#fff; border:1px solid #ebebeb; margin-top: 30px;}
.pagination {margin:5px 0;}
.pagination > li > a, .pagination > li > span { color:#40b3df;margin-left:3px;/* border-radius:3px;*/padding: 6px 12px; border:1px solid #40b3df;;}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {background-color:#40b3df; border-color:transparent}
.pagination > li:first-child > a, .pagination > li:first-child > span {background:#fff}
.pagination > li:last-child > a, .pagination > li:last-child > span {background:#fff}
.pagination > li:first-child.active > a, .pagination > li:first-child.active > span {background-color:#40b3df; border-color:transparent; color:#fff;}
.pagination > li:last-child.active > a, .pagination > li:last-child.active > span {background-color:#40b3df; border-color:transparent; color:#fff;}
.top-filter-wrapper {height:45px; background:#f8f8f8;border:1px solid #ebebeb; display:block;margin-bottom:12px; padding:4px;}
.top-filter-wrapper .form-control {background-color:#fff;  box-shadow: none;}
.top-filter-wrapper .custom-select:after {background: #fff;}
.top-filter-wrapper .paginas-total-w {line-height:35px; font-size:12px; vertical-align:middle; margin:0 10px;}

/*CUSTOM SELECT*/
label.custom-select {position: relative;display: inline-block;width: 100%;margin-bottom: 12px;}
label.custom-select .form-control{cursor: pointer; height: 33px;}
label.custom-select .glyphicon{position:absolute; right:4px; top:10px; z-index:10; background:#fff;}
.custom-select select {display: inline-block;margin: 0;font: inherit;outline:none; font-size: 14px;-webkit-appearance:none;}
@media screen and (-webkit-min-device-pixel-ratio:0) {.custom-select select {padding-right:30px;} }
.custom-select select:focus {-webkit-box-shadow: 0 0 3px 1px #000;-moz-box-shadow: 0 0 3px 1px #000;box-shadow: 0 0 3px 1px #000;}
.custom-select:after {content: "▼"; position: absolute;top: 1px;right: 1px; height:31px; font-size: 12px;line-height: 32px;padding: 0px 8px; background: #fff; border-left: 1px solid #ccc; color: #40b3df;pointer-events:none;-webkit-border-radius: 0;-moz-border-radius: 0;border-radius: 0;}
.no-pointer-events .custom-select:after {content: none;}
label.custom-select.disabledSelect:after {background: #eee; color:#ccc;}
label.custom-select.disabledSelect .form-control {border: 1px solid #ccc; color:#ccc; cursor: not-allowed;}


/*CONTACTOS*/
.g-recaptcha-error{display:none; margin-top: 4px; color:red;}

/* ===================== FOOTER ===================== */
.newsletter-wrapper {line-height: normal; padding-top:2px;}
.newsletter-wrapper .form-group {margin:0;}
.newsletter-wrapper .text-label {text-align: right; line-height: 40px}
.newsletter-wrapper p {margin:0;}
.newsletter-wrapper input[type="text"] {background: #fff; border:0; border-bottom:1px solid #ebebeb; float: left; width: calc(100% - 43px); }
.newsletter-wrapper .btn {background: #000; padding:5px 12px;}
.newsletter-wrapper .checkbox {margin-top:15px; margin-bottom: 0; margin-left:8px;}
.newsletter-wrapper #newsletterTerms {display: none;}
.newsletter-wrapper .form-wrapper {margin-top: 5px;}
.newsletter-wrapper .form-wrapper > div:first-child {padding-right: 0;}
.newsletter-wrapper .form-wrapper > div:nth-child(2) {padding-left: 0;}
.newsletter-wrapper label.error {color:red;}

/*FOOTER*/
footer a.linkfull {color:#999;}
footer a.aqui{text-decoration: underline; color:#fff;}
footer a.aqui:hover{color:#000;}

.compensa-header {margin-top: 125px;}
.section-separador {margin: 40px 0;}

.accordion-header { padding: 20px; font-size: 16px;}
.accordion-content {margin: 0; border-bottom: 1px solid #ddd;}
.accordion-content div {padding: 20px;}
.fa-chevron-down {margin-top: 5px; font-size: 16px;}

/* Versao que rodava o font awesome angle down no acordeo - FICA AQUI COMO REFERENCIA */
.accordion.open .rotate-if-collapsed {-webkit-transform: rotate(180deg); -moz-transform: rotate(180deg); -ms-transform: rotate(180deg); -o-transform: rotate(180deg); transform: rotate(180deg); }
.accordion.open .accordion-header {background-color: #a8cb17; color: #fff; border-bottom: 0px;}

/* versao em uso de mais/menos no acordeao */
.icon {box-sizing: border-box; transition: transform 0.4s; width: 24px; height: 24px; transform: rotate(180deg); position: relative; }
.accordion.open .icon {transform: rotate(0deg); }
.icon::before {content: ''; display: block; width: 20px; height: 0px; border-bottom: solid 2px #99cb00; position: absolute; bottom: 11px; transform: rotate(90deg); transition: width 0.4s; }
.accordion.open .icon::before {content: ''; display: block; width: 0px; height: 0px; border-bottom: solid 2px #fff; position: absolute; bottom: 11px; transform: rotate(90deg); }
.icon::after {content: ''; display: block; width: 20px; height: 0px; border-bottom: solid 2px #99cb00; position: absolute; bottom: 11px; }
.accordion.open  .icon::after {content: ''; display: block; width: 20px; height: 0px; border-bottom: solid 2px #fff; position: absolute; bottom: 11px; }

h1, h2, h3, h4, h5, h6 {color: #666;}
.titulo-paginas {border-left: 10px solid #40b3df; padding: 5px 0 5px 30px; margin-bottom: 30px; text-transform: none; font-weight: 400; font-size: 18px}
.titulo-paginas.font14 {font-size: 14px}
a.link-paginas {color: #99cb00; text-decoration: underline; font-size: 15px;}
.banner-paginas img {border: 1px solid #ccc; padding: 10px; border-radius: 3px;}
.banner-paginas .titulo-img { position: absolute; top: 11px; background-color: rgba(0,0,0,.3); color: #fff; padding: 20px; left: 11px; right: 11px; font-size: 20px; font-weight: 400; }
.carousel-caption {position: absolute; right: 0; bottom: 10%; left: 0; z-index: 10; color: #fff; text-align: right; max-width: 1140px; margin: 0 auto; }
.carousel-caption h3 {background-color: rgba(0,0,0,.3); padding: 15px; margin-bottom: 0; font-size: 28px; font-weight: 600; color: #fff; text-transform: initial;}
.carousel-caption p {background-color: rgba(0,0,0,.3); padding: 15px; width: auto}

.section-header { width:100%; text-align:left; border-bottom: 1px solid #ccc; line-height:0.1em; margin:10px 0 20px; font-size: 22px; font-weight: bold; padding-bottom: 0px}
.section-header span { background:#fff; padding:0 20px 0 0; }

.play-button {cursor: pointer; width:80px!important; position:absolute;top:50%; left:50%; transform:translateY(-50%) translateX(-50%); -moz-transform:translateY(-50%) translateX(-50%); -webkit-transform:translateY(-50%) translateX(-50%); -o-transform:translateY(-50%) translateX(-50%); -ms-transform:translateY(-50%) translateX(-50%);}

.owl-carousel .owl-video-tn{
  background-size: cover;
  padding-bottom:42.85%; /* 21:9 */
	padding-top: 25px;
}

.owl-video-frame{
	position: relative;
	padding-bottom:42.85%; /* 21:9 */
	padding-top: 25px;
	height: 0;
}
.owl-video-frame iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


/* OWL */
.owl-carousel .owl-nav [class*=owl-] {
	color: #555;
	font-size: 32px;
    width: 30px;
    height: 40px;
    line-height: 35px;
	border-radius: 0;
	text-align: center;
	background-color: none!important;
}
.owl-theme .owl-controls .owl-nav [class*=owl-]{
	color: #555;
}

.owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
	background: rgba(255,255,255,0);
	color: #000;
}

.owl-carousel .owl-prev,
.owl-carousel .owl-next {
	position: absolute;
	top: 45%;
	height: 20px;
	margin: auto !important;
  	z-index:999999;
}

.bloco-slideshow .owl-controls .owl-nav [class*=owl-] {color: #fff; background-color: rgba(255,255,255,.5); font-size: 26px; text-shadow: none; height: 75px; line-height: 70px; text-align: center; }
.owl-carousel .owl-prev {left: 0px!important;}
.owl-carousel .owl-next {right: 0px!important;}
.bloco-slideshow .owl-carousel .owl-prev .netx-arrow, .bloco-slideshow .owl-carousel .owl-next .netx-arrow {width:22px;}
.bloco-slideshow  .owl-controls .owl-nav .next-arrow {vertical-align: middle;}

.footer01 {background-color: #999; padding: 40px 0 0 0; color: #fff}
.footer02 {background-color: #dbdbdb; padding: 30px 0;  color:#000;   /*background-image: url(/images/footer-bkg.png); background-repeat: no-repeat; background-size: cover; background-position: center center;*/}
.footer01 h3 {color: #333; text-transform: uppercase; font-size: 14px; font-weight: 700}
.footer-links li {line-height: 26px; text-transform: uppercase;}
.footer-links li a{color: #fff; font-size: 14px}
.footer-links li a:hover {color: #f5f5f5;}
.link-footer-right a {color:#fff;}

.section-homepage {margin: 50px 0;}
.callout-wrapper {border-radius: 0; background-color:#5e5e5e; padding: 45px 60px; /*background-image: url("/images/callout-bkg.png"); */background-repeat: no-repeat; background-size: contain; background-position: bottom; }
.callout-desc {color: #fff; font-size: 16px}
.callout-desc a {text-decoration: underline; color:#fff;}
.link-callout {background-color:#a8cb17; padding: 15px 25px; color:#fff!important; display: inline-block}
.link-callout:hover {background-color:#8ba811;}

#carousel-testemunhos .owl-dots .owl-dot {padding-bottom: 10px}
#carousel-testemunhos .owl-dots .owl-dot span {width: 15px; height: 15px;}
#carousel-testemunhos .owl-dots .owl-dot.active span , #carousel-testemunhos .owl-dots .owl-dot:hover span {background: #333;}
#carousel-testemunhos img {border-radius: 200%}
#carousel-testemunhos .item /*{padding: 50px 30px 0px 30px; }*/
#carousel-testemunhos h3 {font-weight: 600; margin-bottom: 0px; text-transform: initial; font-size: 18px; padding-bottom: 0px}
#carousel-testemunhos h5 {font-weight: 400; margin-bottom: 0px; text-transform: initial; font-size: 14px; font-style: italic; padding: 5px 0 10px 0}
.testemunho-wrapper {background-color:#f5f5f5; background-image: url("/images/bkg-testemunhos.png"); background-repeat: no-repeat; background-size: cover; background-position: center center; }
#emailNewsletter {display: inline-block; width: 100%; height: 40px; margin-right: 0; border: 0px;}
.btn.newsletter {position: absolute; right: 0; top: 0; height: 40px; background-color:#a8cb17; color: #fff}
.border-box {display: block; float: left; width: calc(25% + 1px); margin: 0 -1px -1px 0; border: 1px solid #eee; padding:0; color: #333; overflow:hidden;}
.caption-aep {padding: 15px; position: relative; background-color: #fff; font-weight: 600; font-size: 14px}
.caption-aep:after {bottom: 100%; left: 50%; border: solid transparent; content: " "; height: 0; width: 0; position: absolute; pointer-events: none; border-color: rgba(255, 255, 255, 0); border-bottom-color: #fff; border-width: 7px; margin-left: -7px; }
.image-hover:hover .aep-empresas-img {transform: scale(1.2);}

.navbar {z-index: 99; padding: 15px 0 ;}

.bg-white {background-color: #fff;}
.padding15 {padding: 0 15px}
.topnav li a { margin-top: 5px; display: inline-block; margin-left:30px; color: #fff; font-weight: 600; font-size: 15px; text-transform: capitalize; line-height: 57px; }
.topnav li a:hover {color: #76695e}
.topnav li a:after {display:block; content: ''; border-bottom: solid 3px #76695e; transform: scaleX(0); transition: transform 150ms ease-in-out; }
.topnav li a:hover:after {transform: scaleX(1);}
.topnav li a.active {color: #76695e}
.topnav li a.active:after {transform: scaleX(1);}

a.press-release-link {color: #a8cb17; font-weight: 600; text-decoration: underline; font-size: 14px}
a.press-release-link:hover {color: #93b116;}
.press-release-link .fa-file-pdf {margin-right: 10px; text-decoration: none; font-size: 20px;}

.slider-aep-caption-wrapper {padding: 50px 0 30px 0; margin: 60px auto 280px;  max-width: 75%; text-align: center; background-color: #fff;}
.slider-aep-caption{background: white; padding: 80px 20px 20px 20px; width: 75%; text-align: center; margin: 40px auto }
.slider-aep-wrapper {background-color:#fff; background-image: url("/images/world-map.png"); background-repeat: no-repeat; background-size: cover; background-position: center center; }
.slider-aep-caption h5 {font-style: italic; font-size: 18px}
.slider-aep-caption h3 {font-weight: 600; font-size: 20px}

.slider-aep .owl-theme .owl-controls .owl-nav [class*=owl-] {z-index: 99999; color: #fff!important; font-size: 28px; margin: 5px; padding: 2px 0px; background: #3eafd9; display: inline-block; cursor: pointer; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; }
.slider-aep .owl-carousel .owl-prev, .slider-aep .owl-carousel .owl-next {top: 25%; }
.slider-aep .owl-carousel .owl-prev {left: 10%!important;}
.slider-aep .owl-carousel .owl-next {right: 10%!important;}

.circulo-verde {position: relative; background-color:#add01e; height:15px; border-radius:50%; -moz-border-radius:50%; -webkit-border-radius:50%; width:15px; margin: -9px auto;}
.circulo-verde-small {position: absolute; background-color:#add01e; height:10px; border-radius:50%; -moz-border-radius:50%; -webkit-border-radius:50%; width:10px;}
.circulo-verde-small.circulo-direita {top: -5px; right: 40%;}
.circulo-verde-small.circulo-esquerda {top: -5px; left: 40%;}
.circulo-verde::before {position: absolute; content: ''; height: 27px; width: 27px; border: 2px solid #40b3df; background-color: transparent; border-radius: 50%; -moz-border-radius: 50%; -webkit-border-radius: 50%; top: -6px; left: -6px;}
.data-divisor {position: absolute; top: 55%; z-index: 99; width: 100%;}
.linha-cinza {width: 100%; height: 1px; background-color: #ccc}
.slider-data {color: #40b3df; font-size: 36px; text-align: center; margin-top: 10%; }

.eventos_sidebar a{display: inline-block; font-weight: 600}
.eventos_sidebar a:hover{opacity: .7}

/* ORGANIGRAMA */
.org-members {background-color: #f7f7f7;}
.org-chart-wrapper h2 {font-size: 15px; color: #a5c903; font-weight: 600; margin-bottom: 40px;}
.org-chart-wrapper {font-family: 'Open Sans'; position: relative; }
.org-chart-wrapper * {-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
.org-chart {display: block; clear: both; margin-bottom: 30px; position: relative; }
.org-chart.cf:before, .org-chart.cf:after {content: " "; display: table;}
.org-chart.cf:after {clear: both; }
.org-chart.cf {*zoom: 1; }
.org-chart ul {padding: 0; margin: 0; list-style: none; }
.org-chart ul li {position: relative; }
.org-chart ul li span {display: block; border: 0px; text-align: center; overflow: hidden; text-decoration: none; color: #fff; font-size: 12px; box-shadow: 4px 4px 9px -4px rgba(0, 0, 0, 0.4); -webkit-transition: all linear 0.1s; -moz-transition: all linear 0.1s; transition: all linear 0.1s; background: #3fb2de; padding: 20px 20px; border-radius: 5px; font-size: 16px; }
.org-chart ul li span:hover {background: #9fd9ef; }
.org-chart .board {width: 70%; margin: 0 auto; display: block; position: relative; }
.org-chart .board:before {content: ""; display: block; position: absolute; height: 90%; width: 0px; border-left: 2px solid #a5c903; margin-left: 49%; top: 15px; }
.org-chart ul.columnOne {height: 90px; position: relative; width: 100%; display: block; clear: both; }
.org-chart ul.columnOne li {width: 30%; margin: 0px auto; top: 10px; }
.org-chart ul.columnTwo {position: relative; width: 100%; display: block; height: 90px; clear: both; }
.org-chart ul.columnTwo li:first-child {width: 30%; float: left; }
.org-chart ul.columnTwo li {width: 30%; float: right; }
.org-chart ul.columnTwo:before {content: ""; display: block; position: relative; width: 80%; height: 10px; border-top: 2px solid #a5c903; margin: 0 auto; top: 40px; }
.org-chart ul.columnHalf {position: relative; width: 100%; display: block; height: 90px; clear: both; }
.org-chart ul.columnHalf li {width: 30%; float: right; }
.org-chart ul.columnHalf:before {content: ""; display: block; position: relative; width: 51%; height: 10px; border-top: 2px solid #a5c903; top: 40px; margin-left: auto; }

#logar label.checkbox, #area .recupera_activacao, #area .recupera_pass {font-size: 12px}
#logar label.checkbox::before {width: 19px; height: 19px;}
#logar label.checkbox::after {width: 10px; height: 5px; background: transparent; top: 6px; left: 5px; border-width: 2px;}
#formcontactos .form-control, #logar .form-control{border:none; background:#f7f7f7; border: 1px solid #dfdfdf; height: 35px;}
#formcontactos textarea.form-control:focus, #formcontactos input[type="text"].form-control:focus, #formcontactos input[type="text"]:focus {border: 1px solid #999; -webkit-box-shadow: none !important;; -moz-box-shadow: none !important;; box-shadow: none !important;;}
#formcontactos button, #formaccoes button,#formbow button, #formPropostaSocioEfectivo button, #formCertificadoOrigemNovo button,#formCertificadoOrigem button, #formepago button, #formegratuito button {background: #40b3df; color:#fff; cursor: pointer; border:none;}
#formcontactos button:hover {background: #308eb2}
#formcontactos label.error {font-size: 12px}
.bloco-newsletter h5 {font-size: 14px; margin-top: 15px;}
a.newsletter-link {color: #40b3df;}
a.newsletter-link:hover {color: #2a8bb0;}
.seta-transparente {position: absolute; color: #fff; padding: 5px 10px; bottom: 0; left:0; background-color: rgba(0,0,0,.2);}
.bottom {background-color: #555; padding: 5px 0;}
.titulo-galeria {position:absolute; bottom: 0; left: 0; right: 0; padding: 20px 5px; z-index: 9999; text-align: center; color: #fff; background-color: rgba(0,0,0,.5);}
.blueimp-gallery>.prev, .blueimp-gallery>.next, .blueimp-gallery>.close {color: #fff!important;}
.carousel-3-imagens .owl-controls .owl-nav [class*=owl-] { color: #fff; font-size: 20px; margin: 5px; padding: 4px 7px; background: rgba(0,0,0,.3); display: inline-block; cursor: pointer; -webkit-border-radius: 0px; -moz-border-radius: 0px; border-radius: 0px; height: 60px; line-height: 54px; top: 50%; transform: translateY(-50%); }
.carousel-3-imagens .owl-controls .owl-nav [class*=owl-]:hover {background: rgba(0,0,0,.6);color: #fff;}

#lang {float: left;}
.cc-cookies {background-color: #39a0c7; z-index: 9999999999; top: auto; bottom: 0}

.newsletter-wrapper .checkbox { margin-left: 0px; }
.newsletter-aceito a {color: #fff; font-weight: 600}

.absolute50-1 {position: absolute; height: 50%; left:0; right:0; top: 0; text-align: center}
.absolute50-2 {position: absolute; height: 50%; left:0; right:0; bottom: 0; text-align: center}

/* Organigrama media query */
@media all and (max-width: 767px) {
  .org-chart .board { margin: 0px; width: 100%; }
}




ul.menuprincipal .dropdown-menu {margin-left:0;}
ul.menuprincipal li a.nourl{cursor: pointer;}
ul.menuprincipal li:hover .dropdown-menu{opacity: 1 !important;top: 72px;-webkit-transition: all .1s ease-in-out;  -moz-transition: all .1s ease-in-out; -o-transition: all .1s ease-in-out; -ms-transition: all .1s ease-in-out; transition: all .3s ease-in-out;-webkit-transform: perspective(1px) translateZ(0); transform: perspective(1px) translateZ(0);visibility: visible}
ul.menuprincipal li .dropdown-menu a{display: block;clear: both; float: left;}
ul.menuprincipal li .dropdown-menu a { font-size: 13px; text-transform: unset; padding: 10px 5px; line-height: 20px; margin: 0px 10px; }
.nourl {cursor: pointer}

.associado-wrapper {border: 1px solid #ccc; padding: 38px 25px;}
a.btn-azul-grande {background-color: #40b3df; color: #fff; padding:20px; width: 100%; display: block; text-align: center;}
a.btn-azul-grande:hover {background-color: #2a8bb0;}

/* 5 colunas */
.col-2dot4, .col-md-2dot4 {position: relative; width: 100%; min-height: 1px; padding-right: 15px; padding-left: 15px; }
@media (min-width: 720px) {
    .col-md-2dot4 { -webkit-box-flex: 0; -ms-flex: 0 0 20%; flex: 0 0 20%; max-width: 20%; }
}

header {position:fixed; width: 100%; z-index: 1049}

button, .link-callout, .link-paginas, .btn-azul-grande { font-weight: 700; letter-spacing: .05rem; font-size: 14px;}


#video {position: relative; background: #fff; width: 100%; margin: 0px auto; }
#video img {position: absolute; top: 0; left: 0; width: 100%;}
#video img,
#video iframe { display: block; }
#play {position: absolute; top: 0; left: 0; width: 100%; height: 100%; cursor: pointer; background-size: 60px 85px; z-index: 9999; }

.videoWrapper {	position: absolute;
	padding-bottom:42.85%; /* 21:9 */
	padding-top: 25px;
	top: 0;
   left: 0;
   z-index: 5;
   width: 100%;
   height: 100%;
 }
.videoWrapper iframe {position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

.compensa-header-home {margin-top: 97px}
.nome-associado {font-size: 16px; font-weight: 600; margin-bottom: 5px}
.empresa-associado {font-size: 13px; font-weight: 600; font-style: italic}

.noticias-home {background: #eee;}
.noticias-home .section-header span {background: #eee}
.bold-it {font-weight: 700}
.font-smaller {font-size: 80%}

.social li a {margin-right: 5px}
.social li a i {font-size: 20px}

.pol-privacidade a {color: #fff}
.pol-privacidade a:hover {color: #f5f5f5}

.table th, .table td {
	vertical-align: middle;
}

.table label.checkbox {padding-right: 25px}

/*.form-control {height: calc(2.25rem + 2px);}*/
label:not(.form-label, .checkbox) {font-weight: 600}

.form-group { margin-bottom: 1.5rem; }
.flex-input {display: flex; flex-grow: 1}
#formPropostaSocioEfectivo .form-group {margin-bottom: 1rem;}

.custom-file-label::after {
	content: "Navegar";
}


.iconslogin-mobile a:link,
.iconslogin-mobile a:hover,
.iconslogin-mobile a:visited,
.iconslogin-mobile a:active {font-size: 18px; color:#fff;}

.open-video {position: absolute; bottom: 10px; left: 10px; width: 65px; z-index: 9999; cursor: pointer}
.open-video img {opacity: .8}
.open-video.noticia-detalhe {position: absolute; bottom: inherit; top:50%; left:50%; transform:translateY(-50%) translateX(-50%); -moz-transform:translateY(-50%) translateX(-50%); -webkit-transform:translateY(-50%) translateX(-50%); -o-transform:translateY(-50%) translateX(-50%); -ms-transform:translateY(-50%) translateX(-50%); width:75px; z-index: 9; cursor: pointer}

.ui-menu {max-height: 35vh !important; overflow-x: hidden !important; overflow-y: auto !important;}



.loader {right: 10px; top: 36px;}

.treeview label.checkbox {width: 100%}



@media (min-width: 768px) {

.compensa-header {margin-top: 200px;}

}


/*MEDIA*/

/* xs */
@media (max-width: 767px) {
	.modal:before{display:none;}
	.modal-dialog {width: 95%;}
	.modal-body, .modal-body *{word-wrap:break-word; }
	.carousel-caption {padding-top: 0px; position: relative; right: 0; bottom: 0; left: 0; z-index: 10; color: #fff; text-align: right; }
	.carousel-caption h3, .carousel-caption p {width: 100%; display: block;}
	.border-box {display: block; float: left; width: calc(100% + 1px); margin: 0 -1px -1px 0; border: 1px solid #eee; padding:0; color: #333;}
	.aep-empresas {padding: 0 15px;}
	.logo-img {width: 120px;}
	.banner-paginas .titulo-img {font-size: 13px;}
	.topnav li a {line-height: initial;}
	.navbar-brand {top: 0;}
/*	header { position: fixed; width: 100%}
	.wrap {padding-top: 58px;}*/
	.rcplus {width: 240px}
}


/* sm */
@media (min-width: 768px) and (max-width: 991px) {



}


@media (max-width: 991px) {

}

/* md */
@media (min-width: 992px) {

}

/* lg */
@media (min-width: 1200px) {

}
