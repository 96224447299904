@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* CORES */
/* Página Aluguer de Espaços Formativos- card alternado*/
.tituloalternado {
  color: #1B9CA6;
  font-size: 22px;
  font-weight: bold;
  font-family: "Montserrat";
}

.titulo {
  color: #666666;
  font-size: 22px;
  font-weight: bold;
  font-family: "Montserrat";
}

.textoalternado {
  color: #666666;
  font-size: 14px;
  word-wrap: break-word;
  font-family: "Montserrat";
}

.bg-grey {
  background: #F5F5F5;
}

.titleitem {
  font-weight: bold;
  font-family: "Montserrat";
  text-align: center;
}

.max-width {
  max-width: 350px !important;
}

.txtAluguer {
  font-size: 14px;
  font-family: "Montserrat";
  color: #666666;
}

.txtContacto {
  font-size: 16px;
  font-family: "Montserrat";
  text-align: right;
  color: #666666;
}

.logoContactos {
  color: #1B9CA6 !important;
  font-size: 18px !important;
}

.tituloContact {
  font-size: 20px;
  font-family: "Montserrat";
  font-weight: bold;
}

.dataCurso {
  font-size: 12px;
  color: #005670;
}

.slick-track {
  align-items: stretch;
  display: flex;
}

.slick-slide {
  height: auto;
}

.textoprogramasesp {
  font-weight: 500;
  color: #666666;
  font-size: 15px;
  font-family: "Montserrat";
  text-align: left;
}

/* SubMenu Soluções */
#submenu_solucoes {
  width: 273px;
  background: #F7F7F7;
  top: 150px;
  right: 50px;
  height: 520px;
  float: right;
  position: fixed;
  padding: 20px;
}

#submenu_solucoes ul {
  list-style: none;
  text-align: right;
}

.submenu_li {
  color: #454545;
  font-size: 14px;
  white-space: nowrap;
  margin: 10px 0;
  font-family: "Montserrat";
}

.submenu_li a {
  transition: all 0.3s ease-in-out;
}

.submenu_li a:hover {
  font-weight: 700;
}

/* HEADER */
.bg-transp {
  background-color: transparent;
}

.golftituloc {
  color: #303030;
  font-size: 20px;
  line-height: 26px;
  font-style: normal;
  font-weight: 500;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.4s ease-in-out;
  z-index: 999;
}

.borderradius {
  border-radius: 50%;
  box-shadow: none;
  overflow: hidden;
}

.title_2cols {
  color: #303030;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  text-transform: none;
}

.separator.small {
  height: 2px;
  margin: 13px auto 36px;
  background-color: #cbcfd7;
  width: 50px;
}

.footergolf {
  background-color: #262626;
  color: white;
  width: 100%;
  padding-bottom: 0px !important;
}

.texto-footer {
  color: white !important;
  font-size: 12px;
  line-height: 22px;
}

a.texto-footer:hover {
  color: #005670 !important;
}

.twocolumns {
  -moz-column-count: 2;
       column-count: 2;
}

@media screen and (max-width: 767px) {
  .twocolumns {
    -moz-column-count: 1;
         column-count: 1;
  }
}
.bg-grey {
  background: #f5f5f5;
}

.tituloregulamento {
  color: #303030;
  font-size: 32px;
  line-height: 36px;
  font-style: normal;
  font-weight: 600;
  text-transform: none;
}

.subtitleregulamento {
  color: #303030;
  font-size: 20px;
  line-height: 36px;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
}

.borderrightgolf {
  border-right: 1px solid black;
  height: 75%;
}

.titulopatrocionios {
  color: #666666;
  font-size: 18px;
  font-weight: bold;
  font-family: "Montserrat";
}