.small_label {
  font-size: 1rem;
  display: inline-block;
  padding: 5px 0px;
}

.big_label {
  font-size: 2rem;
  display: inline-block;
  padding: 5px 0px;
}

a.cta {
  display: inline-block;
  position: relative;
  font-size: 1rem;
}